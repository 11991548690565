import React from 'react';
import ReactDOM from 'react-dom/client';
import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css';
import App from './App';
import {Provider} from "react-redux";
import store from "./redux/store";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import PantallaOrdenes from "./PantallaOrdenes";
import PantallaDetalleOrden from "./PantallaDetalleOrden";
import PantallaTransaccion from "./PantallaTransaccion";
import PantallaAdministracion from "./PantallaAdministracion";
import PantallaEstadoOrden from "./PantallaEstadoOrden";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<App/>}>
                        <Route path='ordenes'>
                            <Route index element={<PantallaOrdenes/>}/>
                            <Route path=':nroOrden'>
                                <Route index element={<PantallaDetalleOrden/>}/>
                                <Route path='estado' element={<PantallaEstadoOrden/>}/>
                                <Route path='transacciones/:idTransaccion' element={<PantallaTransaccion/>}/>
                            </Route>
                        </Route>
                        <Route path='administracion'>
                            <Route index element={<PantallaAdministracion/>}/>
                        </Route>
                    </Route>
                </Routes>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
);
