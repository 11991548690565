import React from "react";
import './Navegacion.scss'
import logo from '../img/logo_solnik.svg'
import {useLocation, useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {desloguear} from "../redux/authReducer";

type Seccion = {
    link: string | (() => void),
    titulo: string,
    icono: string,
    nivel_requerido: number
}

const Navegacion: React.FC = () => {
    const dispatch = useAppDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const pathActual = location.pathname.split('/')[1] || ''
    const usr = useAppSelector(state => state.auth.usuario)

    const secciones: Array<Seccion> = [
        {link: 'ordenes', titulo: 'Ordenes', icono: 'fas fa-list', nivel_requerido: 2},
        {link: 'administracion', titulo: 'Administración', icono: 'fas fa-cog', nivel_requerido: 1}
    ]

    const BotonNav: React.FC<{ seccion: Seccion }> = ({seccion}) => {
        return (
            <div className={'botonNav' + (pathActual === seccion.link ? ' activo' : '')}
                 onClick={typeof seccion.link === 'string' ?
                     () => navigate(seccion.link.toString()) : seccion.link}>
                <i className={`${seccion.icono} me-2`}/>{seccion.titulo}
            </div>
        )
    }

    return (
        <div className='bg-light'>
            <div className='container'>
                <div className='d-flex'>
                    <img src={logo} className='logoNav' alt='Solnik'/>
                    <div className='botonera'>
                        {secciones
                            .filter(s => s.nivel_requerido >= (usr?.nivel_acceso || 99))
                            .map((s, k) => (
                                <BotonNav key={k} seccion={s}/>
                            ))}
                    </div>
                    <BotonNav seccion={{
                        titulo: 'Cerrar Sesión',
                        link: () => {
                            dispatch(desloguear())
                        },
                        icono: 'fas fa-sign-out',
                        nivel_requerido: 99
                    }}/>
                </div>
            </div>
        </div>
    )
}

export default Navegacion