import React, {useCallback, useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import config from "./config";
import CuadroJson from "./controles/CuadroJson";
import Enlaces from "./controles/Enlaces";
import SepEnlace from "./controles/SepEnlace";
import {useAppSelector} from "./redux/hooks";

export interface DetalleTransaccion {
    resultado_dep: string
    fechayhora_envio: string
    transaction_id: string
    tipo: string
    pedido_json: string
    respuesta_json: string
    pedido_cts: string
    respuesta_cts: string
}

const PantallaTransaccion: React.FC = () => {
    const transaccionInicial: DetalleTransaccion = {
        resultado_dep: '', fechayhora_envio: '', transaction_id: '', tipo: '', pedido_json: '', respuesta_json: '',
        pedido_cts: '', respuesta_cts: ''
    }
    const [transaccion, setTransaccion] = useState<DetalleTransaccion>(transaccionInicial)
    const params = useParams()
    const nroOrden = params['nroOrden']
    const idTransaccion = params['idTransaccion']
    const usr = useAppSelector(state => state.auth.usuario)

    const cargarTransaccion = useCallback(async () => {
        if (nroOrden !== undefined && idTransaccion !== undefined)
            await fetch(config.api +
                'ordenes/transaccion?nro_orden=' + encodeURIComponent(nroOrden) +
                '&id_transaccion=' + encodeURIComponent(idTransaccion), {
                method: 'get',
                headers: {
                    'Authorization': `Bearer ${usr?.token}`
                }
            })
                .then(res => res.json())
                .then(setTransaccion)
                .catch(err => alert(err))
    }, [nroOrden, idTransaccion])

    useEffect(() => {
        cargarTransaccion().finally()
    }, [cargarTransaccion])

    return (
        <>
            <Enlaces>
                <Link to='/ordenes'>Ordenes</Link><SepEnlace/>
                <Link to='../'>Detalle de la Orden</Link><SepEnlace/>
                Detalle de la Transacción
            </Enlaces>
            <h4>Detalle de Transacción</h4>
            <table className='table table-bordered table-sm table-striped'>
                <thead>
                <tr>
                    <th>Id de Transaccion</th>
                    <th>Tipo</th>
                    <th>Resultado DEP</th>
                    <th>F. Envio</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{transaccion.transaction_id}</td>
                    <td>{transaccion.tipo}</td>
                    <td>{transaccion.resultado_dep}</td>
                    <td>{transaccion.fechayhora_envio}</td>
                </tr>
                </tbody>
            </table>
            <div className='row mb-3'>
                <div className='col-lg-3'><h4>Envío JSON</h4></div>
                <div className='col-lg-9'>
                    <CuadroJson datos_json={transaccion.pedido_json}/>
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col-lg-3'><h4>Respuesta JSON</h4></div>
                <div className='col-lg-9'>
                    <CuadroJson datos_json={transaccion.respuesta_json}/>
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col-lg-3'><h4>Envío CTS</h4></div>
                <div className='col-lg-9'>
                    <CuadroJson datos_json={transaccion.pedido_cts}/>
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col-lg-3'><h4>Respuesta CTS</h4></div>
                <div className='col-lg-9'>
                    <CuadroJson datos_json={transaccion.respuesta_cts}/>
                </div>
            </div>
        </>
    )
}

export default PantallaTransaccion