import React, {useImperativeHandle, useRef} from "react";

type Props = {
    titulo?: React.ReactNode,
    value: string,
    onChange: (arg: string) => void,
    autoFocus?: boolean,
    password?: boolean,
    placeholder?: string,
    invalido?: boolean,
    onFocus?: () => void
}

const InputTexto = React.forwardRef<HTMLInputElement, Props>((
    {
        titulo, value, onChange, autoFocus, password,
        placeholder, invalido, onFocus
    }, ref
) => {
    const refLocal = useRef<HTMLInputElement>(null)
    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(ref, () => refLocal.current)

    return (
        <div className='input-group'>
            {titulo !== undefined && (<div className='input-group-text'>{titulo}</div>)}
            <input type={password ? 'password' : 'text'} onFocus={onFocus} ref={refLocal}
                   className={'form-control' + (invalido ? ' is-invalid' : '')}
                   autoFocus={autoFocus} placeholder={placeholder}
                   value={value} onChange={v => onChange(v.target.value)}/>
        </div>
    )
})

export default InputTexto