import React, {useCallback, useEffect, useState} from "react";
import Enlaces from "./controles/Enlaces";
import SepEnlace from "./controles/SepEnlace";
import {Link, useParams} from "react-router-dom";
import CuadroJson from "./controles/CuadroJson";
import config from "./config";
import Spinner from "./controles/Spinner";
import {useAppSelector} from "./redux/hooks";

type EstadoOrden = {
    datos_json: string
}

interface DatosDispositivo {
    descripcion: string,
    nro_serie: string
}

interface DatosOrden {
    nro_orden: string,
    fecha_orden: string,
    tipo_orden: string,
    customer_id: string,
    dispositivos: DatosDispositivo[]
}

const PantallaEstadoOrden: React.FC = () => {
    const [cargando, setCargando] = useState<boolean>(true)
    const [estado, setEstado] = useState<EstadoOrden>(
        {datos_json: ''}
    )
    const [info, setInfo] = useState<DatosOrden | undefined>(undefined)
    const params = useParams()
    const nroOrden = params['nroOrden']
    const usr = useAppSelector(state => state.auth.usuario)

    const cargarOrden = useCallback(async () => {
        if (nroOrden !== undefined) {
            await fetch(config.api +
                'ordenes/consulta?nro_orden=' + encodeURIComponent(nroOrden), {
                method: 'get',
                headers: {
                    'Authorization': `Bearer ${usr?.token}`
                }
            })
                .then(res => res.json())
                .then(setEstado)
                .catch(err => alert(err))
            setCargando(false)
        }
    }, [nroOrden])

    useEffect(() => {
        cargarOrden().finally()
    }, [cargarOrden])

    useEffect(() => {
        try {
            const j = JSON.parse(estado.datos_json)
            const o = j.orders[0]
            const d = o.deliveries[0].devices
            const i: DatosOrden = {
                nro_orden: o.orderNumber,
                fecha_orden: o.orderDate,
                tipo_orden: o.orderType,
                customer_id: o.customerId,
                dispositivos: d.map((ds: { assetTag: any; deviceId: any; }) => {
                    return {descripcion: ds.assetTag, nro_serie: ds.deviceId}
                })
            }
            setInfo(i)
        } catch (e) {
            console.log(e)
            setInfo(undefined)
        }
    }, [estado])

    return (
        <>
            <Enlaces>
                <Link to='/ordenes'>Ordenes</Link><SepEnlace/>
                <Link to='../'>Detalle de la Orden</Link><SepEnlace/>
                Estado de la Orden
            </Enlaces>
            <h4>Estado de la Orden (API)</h4>
            {cargando ? <Spinner/> : (
                <>
                    {info !== undefined && (
                        <table className='table table-bordered table-sm table-striped small'>
                            <thead>
                            <tr>
                                <th>No. de Orden<br/><span className='text-muted small fw-normal'>orderNumber</span>
                                </th>
                                <th>Fecha<br/><span className='text-muted small fw-normal'>orderDate</span></th>
                                <th>Tipo de Orden<br/><span className='text-muted small fw-normal'>orderType</span></th>
                                <th>ID Cliente<br/><span className='text-muted small fw-normal'>customerId</span></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{info.nro_orden}</td>
                                <td>{info.fecha_orden}</td>
                                <td>{info.tipo_orden}</td>
                                <td>{info.customer_id}</td>
                            </tr>
                            <tr>
                                <td colSpan={4}>
                                    <strong>Artículos</strong>
                                </td>
                            </tr>
                            {info.dispositivos.map((d, k) => (
                                <tr key={k}>
                                    <td>{d.nro_serie}</td>
                                    <td colSpan={3}>{d.descripcion}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    )}
                    <div className='row mb-3'>
                        <div className='col-lg-3'><h4>Respuesta JSON</h4></div>
                        <div className='col-lg-9'>
                            <CuadroJson datos_json={estado.datos_json}/>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default PantallaEstadoOrden