import React from "react";

type Estado = {
    codigo: string,
    descripcion: string,
    color: string
}

const VistaEstado: React.FC<{ estado: string }> = ({estado}) => {
    const estados: Array<Estado> = [
        {codigo: 'R', descripcion: 'A enrolar', color: 'info'},
        {codigo: 'N', descripcion: 'Procesado', color: 'success'},
        {codigo: 'V', descripcion: 'A anular', color: 'muted'},
        {codigo: 'E', descripcion: 'Error', color: 'danger'},
        {codigo: 'F', descripcion: 'Error', color: 'danger'},
        {codigo: 'D', descripcion: 'A desenrolar', color: 'warning'},
        {codigo: 'O', descripcion: 'A reemplazar', color: 'warning'}
    ]

    const est = estados.filter(e => e.codigo === estado)[0]
    if (est === undefined) return <React.Fragment/>

    return (
        <div className='text-nowrap'>
            <i className={`fas fa-square text-${est.color} me-2`}/>{est.descripcion}
        </div>
    )
}

export default VistaEstado