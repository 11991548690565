import React, {useCallback, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import config from "./config";
import VistaEstado from "./controles/VistaEstado";
import {Orden} from "./PantallaOrdenes";
import Enlaces from "./controles/Enlaces";
import SepEnlace from "./controles/SepEnlace";
import {useAppSelector} from "./redux/hooks";

export interface Articulo {
    id: number
    estado: string
    nro_orden: string
    cod_articulo: string
    desc_articulo: string
    nro_serie: string
    mensaje: string
}

type TransaccionLista = {
    id: number
    resultado_dep: string
    fechayhora_envio: string
    transaction_id: string,
    tipo: string
}

type Datos = {
    articulos: Array<Articulo>,
    orden: Orden,
    transacciones: Array<TransaccionLista>
}

const PantallaDetalleOrden: React.FC = () => {
    const datosIniciales: Datos = {
        orden: {
            id: 0, nro_orden: '', fecha_orden: '', cant_articulos: 0, estado: '', customer_id: '',
            razon_social: '', reseller_id: ''
        }, articulos: [], transacciones: []
    }
    const [datos, setDatos] = useState<Datos>(datosIniciales)
    const params = useParams()
    const nroOrden = params['nroOrden']
    const navigate = useNavigate()
    const usr = useAppSelector(state => state.auth.usuario)

    const cargarDetalle = useCallback(async () => {
        if (nroOrden !== undefined)
            await fetch(config.api + 'ordenes/detalle?nro_orden=' + encodeURIComponent(nroOrden), {
                method: 'get',
                headers: {
                    'Authorization': `Bearer ${usr?.token}`
                }
            })
                .then(res => res.json())
                .then(setDatos)
                .catch(err => alert(err))
    }, [nroOrden])

    useEffect(() => {
        cargarDetalle().finally()
    }, [cargarDetalle])

    const filtrarArticulos = (arg: Articulo[]): Articulo[] => {
        return arg.filter(a => a.estado !== '-')
    }

    return (
        <>
            <Enlaces>
                <Link to='/ordenes'>Ordenes</Link><SepEnlace/>Detalle de la Orden
            </Enlaces>
            <h4>Datos de la Orden</h4>
            <table className='table table-bordered table-sm table-striped'>
                <thead>
                <tr>
                    <th>Nro. Orden</th>
                    <th>Cliente</th>
                    <th>Fecha</th>
                    <th>Cant. Artículos</th>
                    <th>Estado</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{datos.orden.nro_orden}</td>
                    <td>{datos.orden.razon_social}</td>
                    <td>{datos.orden.fecha_orden}</td>
                    <td>{datos.orden.cant_articulos}</td>
                    <td><VistaEstado estado={datos.orden.estado}/></td>
                </tr>
                <tr>
                    <td colSpan={5}>
                        <button type='button' className='btn btn-sm btn-secondary'
                                onClick={() => navigate('estado')}>
                            <i className='fas fa-network-wired me-2'/>Consultar Estado de la Orden (API)
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
            <h4>Artículos</h4>
            <table className='table table-bordered table-sm table-striped'>
                <thead>
                {/*
                <tr>
                    <th colSpan={4}>
                        <button type='button' className='btn btn-sm btn-secondary'>
                            <i className='fas fa-file-excel me-2'/>Exportar
                        </button>
                    </th>
                </tr>
                */}
                <tr>
                    <th>Cod. Articulo</th>
                    <th>Descripción</th>
                    <th>Nro. Serie / IMEI</th>
                    <th>Estado</th>
                </tr>
                </thead>
                <tbody>
                {filtrarArticulos(datos.articulos).length ?
                    filtrarArticulos(datos.articulos).map((a, k) => (
                        <tr key={k}>
                            <td>{a.cod_articulo}</td>
                            <td>
                                {a.desc_articulo}
                                {a.mensaje !== null && (
                                    <div className='text-danger small'>
                                        <i className='fas fa-times-circle me-1'/>
                                        {a.mensaje}
                                    </div>
                                )}
                            </td>
                            <td>{a.nro_serie}</td>
                            <td><VistaEstado estado={a.estado}/></td>
                        </tr>
                    )) : (
                        <tr>
                            <td colSpan={4}>
                                <i className='fas fa-info-circle text-info me-2'/>No hay artículos para mostrar
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            <h4>Registro de Transacciones</h4>
            <table className='table table-bordered table-sm table-striped'>
                <thead>
                {/*
                <tr>
                    <th colSpan={6}>
                        <button type='button' className='btn btn-sm btn-secondary'>
                            <i className='fas fa-file-excel me-2'/>Exportar
                        </button>
                    </th>
                </tr>
                */}
                <tr>
                    <th>Id de Transaccion</th>
                    <th>Tipo</th>
                    <th>Resultado DEP</th>
                    <th>F. Envio</th>
                </tr>
                </thead>
                <tbody>
                {datos.transacciones.map((t, k) => (
                    <tr key={k} className='small'>
                        <td className='text-nowrap'>
                            <button type='button' className='btn btn-sm btn-secondary me-2'
                                    onClick={() => navigate(`transacciones/${t.id}`)}>
                                <i className='fas fa-search'/>
                            </button>
                            {t.transaction_id}
                        </td>
                        <td>{t.tipo}</td>
                        <td>{t.resultado_dep}</td>
                        <td>{t.fechayhora_envio}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </>
    )
}

export default PantallaDetalleOrden