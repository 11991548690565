import React, {useEffect, useRef, useState} from "react"
import './PantallaLogin.css'
import InputTexto from "../controles/InputTexto";
import logoSolnik from '../img/logo_solnik.svg'
import Spinner from "../controles/Spinner";
import config from "../config";
import {useAppDispatch} from "../redux/hooks";
import {loguear} from "../redux/authReducer";

type DatosLogin = {
    usuario: string,
    clave: string
}

type Validacion = {
    usuario: boolean,
    clave: boolean
}

const PantallaLogin: React.FC = () => {
    const [login, setLogin] = useState<DatosLogin>({usuario: '', clave: ''})
    const [valid, setValid] = useState<Validacion>({usuario: true, clave: true})
    const [cargando, setCargando] = useState<boolean>(false)
    const [error, setError] = useState<string | undefined>(undefined)
    const dispatch = useAppDispatch()

    const inpActual = useRef<string>('')
    const passRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        const enterHandler = (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                if (inpActual.current === 'user') {
                    passRef.current?.focus()
                } else if (inpActual.current === 'pass') {
                    loginHandler().finally()
                }
            }
        }
        document.addEventListener('keydown', enterHandler)
        return () => document.removeEventListener('keydown', enterHandler)
    })

    const loginHandler = async (): Promise<void> => {
        const vld: Validacion = {
            usuario: login.usuario !== '',
            clave: login.clave !== ''
        }
        setValid(vld)
        if (!vld.usuario || !vld.clave) return

        setError(undefined)
        setCargando(true)
        await fetch(config.api + 'login', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(login)
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    setError(res.error)
                } else {
                    dispatch(loguear({usuario: res}))
                }
            })
            .catch(err => alert(err))
        setCargando(false)
    }

    return (
        <div className='fondoLogin'>
            <div className='container text-center'>
                <img src={logoSolnik} alt='Solnik' className='logoSolnik'/>
                <h3 className='mb-3'>Interfaz de Enrolamiento Apple DEP</h3>
                {error !== undefined && (
                    <div className='mb-3 text-center text-danger'>
                        <i className='fas fa-times-circle me-2'/>{error}
                    </div>
                )}
                <div className='row mb-3'>
                    <div className='col-md-6 offset-md-3'>
                        <InputTexto titulo={<i className='fas fa-user'/>} invalido={!valid.usuario}
                                    value={login.usuario} autoFocus placeholder='Nombre de usuario'
                                    onChange={v => setLogin({...login, usuario: v})}
                                    onFocus={() => inpActual.current = 'user'}/>
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-md-6 offset-md-3'>
                        <InputTexto titulo={<i className='fas fa-key'/>} invalido={!valid.clave}
                                    value={login.clave} password placeholder='Contraseña'
                                    onChange={v => setLogin({...login, clave: v})}
                                    onFocus={() => inpActual.current = 'pass'} ref={passRef}/>
                    </div>
                </div>
                {cargando ? <Spinner/> : (
                    <button type='button' className='btn btn-primary' onClick={loginHandler}>
                        <i className='fas fa-sign-in me-2'/>Iniciar Sesión
                    </button>
                )}
            </div>
        </div>
    )
}

export default PantallaLogin