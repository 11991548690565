import React from "react";

const VistaNivelAcceso: React.FC<{ nivel: number }> = ({nivel}) => {
    switch (nivel) {
        case 1:
            return <><i className='fas fa-user me-2'/>Administrador</>
        case 2:
            return <><i className='fas fa-users me-2'/>Usuario</>
        default:
            return <React.Fragment/>
    }
}

export default VistaNivelAcceso