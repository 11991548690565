import React from "react";

const Enlaces: React.FC<{children: React.ReactNode}> = ({children}) => {
    return (
        <div className='bg-light mb-3 p-2 small'>
            {children}
        </div>
    )
}

export default Enlaces