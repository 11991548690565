import React, {useEffect, useState} from "react";
import './CuadroJson.css'

const CuadroJson: React.FC<{ datos_json: string }> = ({datos_json}) => {
    const [vistaJson, setVistaJson] = useState<string>('')
    const [copiado, setCopiado] = useState<boolean>(false)

    useEffect(() => {
        if (datos_json !== '') {
            try {
                setVistaJson(JSON.stringify(JSON.parse(datos_json), null, 2))
            } catch (e) {
                console.log(e)
            }
        }
    }, [datos_json])

    const copiarTexto = (): void => {
        navigator.clipboard.writeText(vistaJson)
            .then(() => {
                setCopiado(true)
                setTimeout(() => setCopiado(false), 3000)
            })
            .catch(err => alert(err))
    }

    const descargarTexto = (): void => {
        const blob = new Blob([vistaJson], {type: 'application/json'})
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = 'datos.json'
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

    return (
        <div className='border'>
            <div className='bg-light p-1 border-bottom'>
                <button type='button' onClick={copiarTexto}
                        className={`btn ${copiado ? 'btn-success' : 'btn-secondary'} btn-sm me-2`}>
                    <i className='fas fa-clipboard me-2'/>Copiar
                    {copiado && (<i className='fas fa-check ms-2'/>)}
                </button>
                <button type='button' className='btn btn-secondary btn-sm' onClick={descargarTexto}>
                    <i className='fas fa-download me-2'/>Descargar
                </button>
            </div>
            <div className='cuadroJson p-3'>
                <pre>{vistaJson}</pre>
            </div>
        </div>
    )
}

export default CuadroJson