import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface AuthState {
    usuario?: UsuarioState
}

export interface UsuarioState {
    id_usuario: number,
    usuario: string,
    token: string,
    nivel_acceso: number
    nombre_completo: string
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        usuario: undefined
    } as AuthState,
    reducers: {
        loguear: (state, action: PayloadAction<AuthState>) => {
            state.usuario = action.payload.usuario
        },
        desloguear: state => state.usuario = undefined
    }
})

export const {loguear, desloguear} = authSlice.actions

export default authSlice.reducer