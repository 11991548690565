import React from 'react';
import './App.scss';
import {useAppSelector} from "./redux/hooks";
import PantallaLogin from "./login/PantallaLogin";
import Navegacion from "./navegacion/Navegacion";
import {Navigate, Outlet, useLocation} from "react-router-dom";

const App: React.FC = () => {
    const usuario = useAppSelector(state => state.auth.usuario)
    const location = useLocation()

    if (usuario === undefined) {
        if (location.pathname !== '/') {
            return <Navigate to='/'/>
        } else {
            return <PantallaLogin/>
        }
    }

    if (location.pathname === '/') {
        return <Navigate to='ordenes'/>
    }

    return (
        <div>
            <Navegacion/>
            <div className='container pt-3'>
                <Outlet/>
            </div>
        </div>
    )
}

export default App;
