import React, {useState} from "react";
import config from "./config";
import {useAppSelector} from "./redux/hooks";
import InputTexto from "./controles/InputTexto";

const EditarEmail: React.FC<{
    id: number, dEmail: string, onCancelar: () => void, onGuardar: () => void
}> = ({id, dEmail, onCancelar, onGuardar}) => {
    const [email, setEmail] = useState<string>(dEmail)
    const usr = useAppSelector(state => state.auth.usuario)

    const guardarHandler = (eliminar: boolean): void => {
        const fd = new FormData()
        fd.append('id', id.toString())
        fd.append('email', email)
        fd.append('eliminar', eliminar ? '1' : '0')

        fetch(config.api + 'admin/editaremail', {
            method: 'post',
            body: fd,
            headers: {
                'Authorization': `Bearer ${usr?.token}`
            }
        })
            .then(() => onGuardar())
            .catch(err => alert(err))
    }

    return (
        <>
            <tr>
                <td>
                    <small className='ps-2'>E-Mail</small>
                    <InputTexto value={email} onChange={setEmail}/>
                </td>
            </tr>
            <tr>
                <td colSpan={4} className='text-end'>
                    <button type='button' className='btn btn-secondary me-2' onClick={onCancelar}>
                        Cancelar
                    </button>
                    <button type='button' className='btn btn-danger me-2'
                            onClick={() => guardarHandler(true)}>
                        Eliminar
                    </button>
                    <button type='button' className='btn btn-primary me-2'
                            onClick={() => guardarHandler(false)}>
                        Guardar cambios
                    </button>
                </td>
            </tr>
        </>
    )
}

export default EditarEmail