import React, {useCallback, useEffect, useState} from "react";
import Enlaces from "./controles/Enlaces";
import config from "./config";
import VistaNivelAcceso from "./controles/VistaNivelAcceso";
import EditarUsuario from "./EditarUsuario";
import {useAppSelector} from "./redux/hooks";
import EditarEmail from "./EditarEmail";

type Usuario = {
    id: number
    usuario: string
    nombrecompleto: string
    nivelacceso: number
    ultimologin: string
}

type Email = {
    id: number
    email: string
}

type DatosAdmin = {
    usuarios: Array<Usuario>
    emails: Array<Email>
}

const PantallaAdministracion: React.FC = () => {
    const [datos, setDatos] = useState<DatosAdmin>({usuarios: [], emails: []})
    const [editUsr, setEditUsr] = useState<number | undefined>(undefined)
    const [editMail, setEditMail] = useState<number | undefined>(undefined)
    const usr = useAppSelector(state => state.auth.usuario)

    const cargarAdmin = useCallback(async () => {
        await fetch(config.api + 'admin', {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${usr?.token}`
            }
        })
            .then(res => res.json())
            .then(setDatos)
            .catch(err => alert(err))
    }, [usr?.token])

    useEffect(() => {
        cargarAdmin().finally()
    }, [cargarAdmin])

    return (
        <>
            <Enlaces>Administración</Enlaces>
            <h4>Configuración de Usuarios</h4>
            <table className='table table-bordered table-sm table-striped'>
                <thead>
                <tr>
                    <th colSpan={4}>
                        <button type='button' className='btn btn-sm btn-secondary'
                                onClick={() => setEditUsr(0)}>
                            <i className='fas fa-user-plus me-2'/>Agregar Usuario
                        </button>
                    </th>
                </tr>
                <tr>
                    <th style={{width: '25%'}}>Usuario</th>
                    <th style={{width: '25%'}}>Nombre Completo</th>
                    <th style={{width: '25%'}}>Nivel de Acceso</th>
                    <th style={{width: '25%'}}>Último Ingreso</th>
                </tr>
                </thead>
                <tbody>
                {editUsr === 0 && (
                    <EditarUsuario id={0} dUsuario={''} dNombreCompleto={''} dNivelAcceso={2}
                                   onCancelar={() => setEditUsr(undefined)}
                                   onGuardar={() => {
                                       setEditUsr(undefined)
                                       cargarAdmin().finally()
                                   }}/>
                )}
                {datos.usuarios.map((u, k) => (
                    <React.Fragment key={k}>
                        <tr>
                            <td className='text-nowrap'>
                                <button type='button' className='btn btn-secondary btn-sm me-2'
                                        onClick={() => setEditUsr(u.id)}>
                                    <i className='fas fa-edit'/>
                                </button>
                                {u.usuario}
                            </td>
                            <td>{u.nombrecompleto}</td>
                            <td><VistaNivelAcceso nivel={u.nivelacceso}/></td>
                            <td>{u.ultimologin}</td>
                        </tr>
                        {editUsr === u.id && (
                            <EditarUsuario id={u.id} dUsuario={u.usuario} dNombreCompleto={u.nombrecompleto}
                                           dNivelAcceso={u.nivelacceso}
                                           onCancelar={() => setEditUsr(undefined)}
                                           onGuardar={() => {
                                               setEditUsr(undefined)
                                               cargarAdmin().finally()
                                           }}/>
                        )}
                    </React.Fragment>
                ))}
                </tbody>
            </table>
            <h4>Configuración de Alertas</h4>
            <table className='table table-bordered table-sm table-striped'>
                <thead>
                <tr>
                    <th colSpan={4}>
                        <button type='button' className='btn btn-sm btn-secondary'
                                onClick={() => setEditMail(0)}>
                            <i className='fas fa-envelope me-2'/>Agregar E-Mail
                        </button>
                    </th>
                </tr>
                <tr>
                    <th>E-Mail</th>
                </tr>
                </thead>
                <tbody>
                {editMail === 0 && (
                    <EditarEmail id={0} dEmail=''
                                 onCancelar={() => setEditMail(undefined)}
                                 onGuardar={() => {
                                     setEditMail(undefined)
                                     cargarAdmin().finally()
                                 }}/>
                )}
                {datos.emails.map((e, k) => (
                    <React.Fragment key={k}>
                        <tr>
                            <td className='text-nowrap'>
                                <button type='button' className='btn btn-secondary btn-sm me-2'
                                        onClick={() => setEditMail(e.id)}>
                                    <i className='fas fa-edit'/>
                                </button>
                                {e.email}
                            </td>
                        </tr>
                        {editMail === e.id && (
                            <EditarEmail id={e.id} dEmail={e.email}
                                         onCancelar={() => setEditMail(undefined)}
                                         onGuardar={() => {
                                             setEditMail(undefined)
                                             cargarAdmin().finally()
                                         }}/>
                        )}
                    </React.Fragment>
                ))}
                </tbody>
            </table>
        </>
    )
}

export default PantallaAdministracion