import React, {useState} from "react";
import InputTexto from "./controles/InputTexto";
import config from "./config";
import {useAppSelector} from "./redux/hooks";

const EditarUsuario: React.FC<{
    id: number, dUsuario: string, dNombreCompleto: string, dNivelAcceso: number,
    onCancelar: () => void, onGuardar: () => void
}> = (
    {id, dUsuario, dNombreCompleto, dNivelAcceso, onCancelar, onGuardar}
) => {
    const [usuario, setUsuario] = useState<string>(dUsuario)
    const [nombre, setNombre] = useState<string>(dNombreCompleto)
    const [nivel, setNivel] = useState<number>(dNivelAcceso)
    const [clave, setClave] = useState<string>('')
    const usr = useAppSelector(state => state.auth.usuario)

    const guardarHandler = (eliminar: boolean): void => {
        const fd = new FormData()
        fd.append('id', id.toString())
        fd.append('usuario', usuario)
        fd.append('nombre', nombre)
        fd.append('nivel', nivel.toString())
        fd.append('clave', clave)
        fd.append('eliminar', eliminar ? '1' : '0')

        fetch(config.api + 'admin/editarusuario', {
            method: 'post',
            body: fd,
            headers: {
                'Authorization': `Bearer ${usr?.token}`
            }
        })
            .then(() => onGuardar())
            .catch(err => alert(err))
    }

    return (
        <>
            <tr>
                <td>
                    <small className='ps-2'>Usuario</small>
                    <InputTexto value={usuario} onChange={setUsuario}/>
                </td>
                <td>
                    <small className='ps-2'>Nombre completo</small>
                    <InputTexto value={nombre} onChange={setNombre}/>
                </td>
                <td>
                    <small className='ps-2'>Nivel de Acceso</small>
                    <select className='form-select' value={nivel}
                            onChange={v => setNivel(parseInt(v.target.value))}>
                        <option value={1}>Administrador</option>
                        <option value={2}>Usuario</option>
                    </select>
                </td>
                <td>
                    <small className='ps-2'>Contraseña</small>
                    <InputTexto value={clave} onChange={setClave} password/>
                </td>
            </tr>
            <tr>
                <td colSpan={4} className='text-end'>
                    <button type='button' className='btn btn-secondary me-2' onClick={onCancelar}>
                        Cancelar
                    </button>
                    <button type='button' className='btn btn-danger me-2'
                            onClick={() => guardarHandler(true)}>
                        Eliminar
                    </button>
                    <button type='button' className='btn btn-primary me-2'
                            onClick={() => guardarHandler(false)}>
                        Guardar cambios
                    </button>
                </td>
            </tr>
        </>
    )
}

export default EditarUsuario