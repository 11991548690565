import React, {useCallback, useEffect, useState} from "react";
import config from "./config";
import VistaEstado from "./controles/VistaEstado";
import {useNavigate} from "react-router-dom";
import Enlaces from "./controles/Enlaces";
import {useAppSelector} from "./redux/hooks";

export interface Orden {
    id: number
    estado: string
    fecha_orden: string
    nro_orden: string
    reseller_id: string
    customer_id: string
    razon_social: string
    cant_articulos: number
}

const PantallaOrdenes: React.FC = () => {
    const [ordenes, setOrdenes] = useState<Array<Orden>>([])
    const navigate = useNavigate()
    const usr = useAppSelector(state => state.auth.usuario)

    const cargarOrdenes = useCallback(async () => {
        await fetch(config.api + 'ordenes', {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${usr?.token}`
            }
        })
            .then(res => res.json())
            .then(setOrdenes)
            .catch(err => alert(err))
    }, [])

    useEffect(() => {
        cargarOrdenes().finally()
    }, [cargarOrdenes])

    return (
        <>
            <Enlaces>Ordenes</Enlaces>
            <h4>Ordenes</h4>
            <table className='table table-bordered table-sm table-striped'>
                <thead>
                {/*
                <tr>
                    <th colSpan={5}>
                        <button type='button' className='btn btn-sm btn-secondary'>
                            <i className='fas fa-file-excel me-2'/>Exportar
                        </button>
                    </th>
                </tr>
                */}
                <tr>
                    <th>Nro. Orden</th>
                    <th>Cliente</th>
                    <th>Fecha</th>
                    <th>Cant. Artículos</th>
                    <th>Estado</th>
                </tr>
                </thead>
                <tbody>
                {ordenes.map((o, k) => (
                    <tr key={k}>
                        <td className='text-nowrap'>
                            <button type='button' className='btn btn-sm btn-secondary me-2'
                                    onClick={() => navigate(o.nro_orden)}>
                                <i className='fas fa-search'/>
                            </button>
                            {o.nro_orden}
                        </td>
                        <td>{o.razon_social}<br/><span className='small text-muted'>{o.customer_id}</span></td>
                        <td>{o.fecha_orden}</td>
                        <td>{o.cant_articulos}</td>
                        <td><VistaEstado estado={o.estado}/></td>
                    </tr>
                ))}
                </tbody>
            </table>
        </>
    )
}

export default PantallaOrdenes